import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import PostListItem from '../components/post';
import { PostData } from '../datas/post';

const Tags = ({ pageContext, data }: {
  pageContext: {
    tag: string;
  };
  data: DataType;
}): JSX.Element => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `"${tag}"タグが付いた記事（${totalCount}件）`;

  return (
    <Layout seo={{ title: `タグ ${tag}` }}>
      <div className="container">
        <div className="level">
          <div className="level-left">
            <h1 className="level-item title is-size-5">{tagHeader}</h1>
          </div>
          <div className="level-right">
            <Link className="level-item" to="/tags/">タグ一覧</Link>
          </div>
        </div>
        {edges.map(({ node }) => (<PostListItem post={node} />))}
      </div>
    </Layout>
  );
};

export default Tags;

type DataType = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allMarkdownRemark: {
    totalCount: number;
    edges: {
      node: PostData;
    }[];
  };
};

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`;
